// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-blindflekk-magasinkonsept-index-tsx": () => import("./../../../src/pages/work/blindflekk-magasinkonsept/index.tsx" /* webpackChunkName: "component---src-pages-work-blindflekk-magasinkonsept-index-tsx" */),
  "component---src-pages-work-blskjell-index-tsx": () => import("./../../../src/pages/work/blskjell/index.tsx" /* webpackChunkName: "component---src-pages-work-blskjell-index-tsx" */),
  "component---src-pages-work-honne-live-index-tsx": () => import("./../../../src/pages/work/honne-live/index.tsx" /* webpackChunkName: "component---src-pages-work-honne-live-index-tsx" */),
  "component---src-pages-work-hundre-dikt-om-fred-index-tsx": () => import("./../../../src/pages/work/hundre-dikt-om-fred/index.tsx" /* webpackChunkName: "component---src-pages-work-hundre-dikt-om-fred-index-tsx" */),
  "component---src-pages-work-index-tsx": () => import("./../../../src/pages/work/index.tsx" /* webpackChunkName: "component---src-pages-work-index-tsx" */),
  "component---src-pages-work-jorden-brenner-og-ingen-bryr-seg-2-index-tsx": () => import("./../../../src/pages/work/jorden-brenner-og-ingen-bryr-seg-2/index.tsx" /* webpackChunkName: "component---src-pages-work-jorden-brenner-og-ingen-bryr-seg-2-index-tsx" */),
  "component---src-pages-work-jorden-brenner-og-ingen-bryr-seg-index-tsx": () => import("./../../../src/pages/work/jorden-brenner-og-ingen-bryr-seg/index.tsx" /* webpackChunkName: "component---src-pages-work-jorden-brenner-og-ingen-bryr-seg-index-tsx" */),
  "component---src-pages-work-uniform-speculative-design-index-tsx": () => import("./../../../src/pages/work/uniform-speculative-design/index.tsx" /* webpackChunkName: "component---src-pages-work-uniform-speculative-design-index-tsx" */)
}

